.doc_tabs {
  background-color: var(--color-bg-footer);
  border-top: 1px solid var(--color-border-header-mid);
  border-bottom: 1px solid var(--color-border-header-bot);
  box-shadow: 0 1px 4px -1px var(--color-border-header-bot);
  @include clearfix;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;

      a {
        display: block;
        padding: 1em;
        color: var(--color-text-link-header);

        &:hover {
          color: var(--color-text-banner);
          text-decoration: none;
        }
      }
    }
  }
}

.doc_menu {
  color: var(--color-text-doc-menu);
  padding-right: 0;
  background-color: var(--color-bg-menu);

  .menu {
    font-size: 14px;
    list-style: none;

    ul {
      list-style: none;
    }
  }

  a {
    color: var(--color-text-doc-menu);
  }
}

.doc_menu-heading {
  color: var(--color-text-doc-menu-hdr);
  padding: 1em $menu-level-pad;
}

.doc_main {
  padding: 0 2em;
}

.doc-header {
  padding-bottom: 2em;
  border-bottom: 1px solid var(--color-border-secondary);
  margin: 4em 0 2em;

  h1 {
    margin: 0;
  }
}

@media only screen and (min-width: #{$bp-desk0}) {
  .doc_tabs {
    ul {
      li {
        float: left;

        &:first-child a {
          padding-left: 38px;
        }
      }

      li.active {
        a {
          color: var(--color-text-banner);
          background-color: lighten($blue-dark, 10%);
        }
      }
    }
  }

  .doc_container {
    display: flex;
    min-height: calc(100vh - #{$page-height-offset});
  }

  .doc_menu {
    min-width: 16em;
    width: 16em;
  }

  .doc_main {
    overflow-x: hidden;

    .doc {
      // border-top: 1px solid $doc-line-lite;
      padding: 4em;
    }

    .doc-title {
      margin-top: 0;
    }
  }

  .doc-header {
    margin: 0 0 2em;
  }

  .doc-content, .legacy-doc-content {
    h1:first-child, h2:first-child {
      margin-top: 0;
    }

    h2 {
      margin: 1em 0;
    }

    table {
      max-width: 100%;
      margin-bottom: 1em;

      th {
        border-bottom: 1px solid var(--color-border-secondary);
      }

      td, th {
        font-size: 14px;
        padding: 1em;
        border-right: 1px solid var(--color-border-secondary);
        vertical-align: middle;

        &:last-child {
          border-right: none;
        }

        // Paragraphs in table cells, WHY???
        p {
          margin: 0;
          line-height: 1.4;
        }
      }

      tr:nth-child(even) {
        td {
          background-color: var(--color-bg-docs-tbl-even);
        }
      }
    }
  }

  .doc-versions {
    padding-bottom: 1em;
    border-bottom: 1px solid var(--color-border-secondary);
    margin-bottom: 1em;

    .label, .separator {
      color: var(--color-text-tertiary);
    }

    .active {
      font-weight: 700;
    }
  }

  #release_menu {
    background-color: $grey-darker;
  }

  // Guides

  // User Ref Docs

  .user_doc .doc-content, .api_doc .doc-content {
    margin-right: 30%;

    h1 {
      font-size: 32px;
    }
  }

  .doc-content {
    .section-nav {
      font-size: 14px;
      border-left: 1px solid var(--color-border-secondary);
      width: 25%;
      position: absolute;
      right: 1em;

      ul {
        padding-left: 2em;
      }
    }
  }

  // Legacy User Ref Docs

  .user_doc .legacy-doc-content {
    margin-right: 35%;
  }

  .user_doc .sect1 {
    h2:first-child {
      margin-top: 0;
    }
  }

  .toc {
    font-size: 14px;
    padding: 0 1em 1em 1em;
    border-left: 1px solid var(--color-border-secondary);
    margin: 0 0 2em 2em;
    width: 25%;
    position: absolute;
    right: 1em;

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      padding-bottom: 0.5em;

      // Pad anchors followed by <ul>'s
      > a {
        display: block;
        padding-bottom: 0.5em;
      }
    }

    // Reset padding from above
    li a:only-child {
      padding-bottom: 0;
    }

    .sectlevel2 {
      padding: 0 0 1em 1em;
    }

    .sectlevel3 {
      padding: 0 0 1em 2em;
    }

    // Don't separate below the top level
    li > ul > li ul {
      padding-bottom: 0;
    }
  }

  #toctitle {
    margin-bottom: 1em;
  }

  // All User Ref Docs

  .user_doc {
    span.image {
      display: block;
    }

    img {
      display: inline-block;
      max-width: 100%;
      height: auto;
    }
  }
} // @media
