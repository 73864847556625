// .off_canvas styles an off-canvas, slide-in style menu for small screens
// General classes, see _header for scoped styles

.off_canvas {
  position: absolute;
  top: 0;
  right: 0;

  width: $site-menu-sm-width;
  height: 100vh;

  transform: translate3d($site-menu-sm-width, 0, 0);
  transition: all 0.5s;

  // background-color: $blue-dark;
  @include banner_grad;
}

// default position for menu effect
main, .site-header .brand-logo {
  transform: translate3d(0, 0, 0);
  transition: all 0.6s;
}

.off_canvas-visible {

  // stop scroll when menu is visible
  overflow: hidden;

  // shift+faded site behind menu effect
  main, .site-header .brand-logo {
    opacity: 0.8;
    transform: translate3d(-40px, 0, 0);
  }

  .off_canvas {
    transform: translate3d(0, 0, 0);
  }
}

.off_canvas-hide {
  display: block;
  padding: 1em;
  color: #fff;
  
}

@media only screen and (min-width: #{$bp-desk0}) {
  main {
    transition: none;
  }

  .off_canvas {
    position: static;
    width: 100%;
    height: auto;
    transform: none;
    background: none;
  }

  .off_canvas-hide, .off_canvas-show {
    display: none;
  }
}
