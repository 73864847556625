// See _post.scss for .post styles

.blog {
  background-color: var(--color-bg-primary);

  summary {
    display: list-item;
    margin-bottom: 1em;
  }

  details {
    p {
      margin-left: 1em;
    }
  }
}

.archive_month_title {
  font-style: italic;
  padding: 0 0 0.5em 0;
  border-bottom: 1px solid var(--color-border-primary);
}

.blog_link {
  width: 100%;
}

.blog_header {
  padding: 2em 0;

  h1 {
    margin: 0;
    font-size: 16px;
  }
}

.pagination-container {
  display: none;
}

.blog .footnotes {
  font-size: 75%;

  li {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: #{$bp-desk0}) {

  .blog_header {
    h1 {
      font-size: 20px;
    }
  }

  .blog_main {
    border-right: 1px solid var(--color-border-primary);
  }

  .blog_side-group {
    padding-bottom: 1em;
    border-bottom: 1px solid var(--color-border-primary);
    margin-bottom: 2em;
  }

  .blog_side-heading {
    font-size: 16px;
    margin: 0 0 1em 0;
  }

  .blog_menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .blog_menu-year {
    font-size: 18px;

    ul {
      margin-top: 1em;
    }
  }

  .blog_menu-month {
    font-size: 16px;
    margin: 0 0 1em 1em;
  }

  .pagination-container {
    display: block;
  }

  .pager-container {
    display: none;
  }
}
