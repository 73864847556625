section.partners {
  border-top: 1px solid var(--color-border-primary);
  padding: 3em 0;
}

.partners-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;

  max-width: 100%;
  margin: 0 auto;
}

.partner {
  padding: 0 1em 2em;
  margin: 0;
  text-align: center;
  width: 100%;

  img {
    filter: var(--partner-img-filter);
    opacity: var(--partner-img-opacity);
  }
}

@media only screen and (min-width: #{$bp-desk0}) {
  section.partners {
    padding: 60px 0;
  }

  .partners-container {
    max-width: 899px;
    // min-width: 730px;
    margin: 0 auto;
  }

  .partner {
    padding: 0 20px 34px;
    margin: 0;
    width: auto;
  }
}
