$site-header-sm-pad: 10px;

.site-header {
  max-width: 100%;
  padding: $site-header-sm-pad;

  @include banner_grad;

  z-index: 9999;

  // give space to menu toggle
  .brand {
    display: block;
    margin-right: 80px;
  }

  .brand-name {
    display: none;
  }

  .brand-logo {
    display: block;
    width: 120px;
    height: auto;
  }
}

.home {
  .site-header {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    background: none;
  }
}

#site_header-nav_toggle {
  position: absolute;
  right: $site-header-sm-pad;
  top: $site-header-sm-pad;

  a {
    color: var(--color-text-banner);
  }
}

@media only screen and (min-width: #{$bp-desk0}) {
  .site-header {

    padding: 30px;

    @include clearfix;

    .brand {
      float: left;
      color: var(--color-text-banner);
      display: block;
    }

    .brand-logo {
      width: 160px;
    }

    .site_nav {
      float: right;
    }
  }

  #site_header-nav_toggle {
    display: none;
  }
} // @media
