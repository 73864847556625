@charset "utf-8";

$font-size-base: 16px;
$fa-font-path: "../fonts/font-awesome";

@import "font-awesome";
@import "twbs_custom";
@import "twbs_overrides";

// Undo Bootstrap pixel font-size
// http://fvsch.com/code/css-locks/#toc-2d

/*
 * Redefine the rem value while keeping it proportional.
 * Useful values, with default font-size of 16px:
 * • 62.5% -> 1rem = 10px, .1rem  = 1px
 * • 125%  -> 1rem = 20px, .05rem = 1px
 */
html {
  font-size: 62.5%;
  overflow-x: hidden; // deals with off-canvas issue
}

// Breakpoints, mobile first

$bp-desk0: 768px;

// Home Grid vars
$hg-gutter-width: 30px;
$hg-container-desktop: 867px;

$triad-gutter: 74px;
$triad-offset: -74px;

// Height of flexbox containers in <main>
// body border + header + footer
$page-height-offset: 257px;

// Site menu
$site-menu-sm-width: 320px;

// Docs Menu
$menu-highlight-bar: 0.25em;
$menu-level-pad: 36px;

@import "mixins";

// High-level, site-wide stuff
@import
  "colors",
  "type",
  "page",   // page containers and colors
  "search",
  "menu",
  "site_nav",
  "off_canvas",
  "header",
  "footer",
  "lightbox",
  "syntax-highlighting";

// Home page
@import
  "banner",
  "triad",
  "platforms",
  "solutions",
  "features",
  "partners";

// Docs
@import "docs";
@import "labels";

// Blog
@import "blog";
@import "post";

// Logo
@import "logo";
