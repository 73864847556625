// Site nav
// See _header and _footer for scoped styles

.site_nav {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin-bottom: 0;

    &:last-child a {
      border-bottom: none;
    }
  }

  a {
    display: block;
    padding: 1em;
    color: var(--color-text-site-nav);
    border-bottom: 1px solid rgba(255,255,255,0.2);

    @include link_background_hover;
  }
}

@media only screen and (min-width: #{$bp-desk0}) {
  .site_nav {
    li {
      float: left;

      &:last-child {
        margin-right: none;
      }

      a, button {
        padding: 0.5em 1em;
        border-radius: 10px;
        border-bottom: none;
        line-height: 1.4;
      }
    }
  }
} // @media
