
// Logo Page

.logo_group {
  display: flex;
}

.logo_example {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 2em;

  h3 {
    text-align: center;
  }

  img {
    display: block;
    width: 80%;
    max-width: 320px;
    height: auto;
    margin: 2em 0;
  }

  img[href*="vertical"] {

  }

  .logo_example-links {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      margin: 0 0.5em;
    }
  }
}

#logos_standard, #logos_bw, #glyph {
  .logo_example {
    background-color: var(--color-bg-logo-light);

    h3 {
      color: var(--color-text-logo-light);
    }
  }
}

#logos_inverse {
  .logo_example {
    background-color: var(--color-bg-logo-dark);

    h3 {
      color: var(--color-text-logo-dark);
    }
  }
}
