

.menu {
  padding: 0;
  margin-bottom: 0;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    margin: 0;
  }

  li > ul {
    overflow: auto;
    max-height: 0vh;

    transition: max-height 0.5s;

    li {
      opacity: 0;
      transition: opacity 0.8s;
    }
  }

  li.menu-open > ul {
    max-height: 200vh; // Some max-height needed for expansion animation

    li {
      opacity: 1;
    }
  }

  li.active > a {
    background-color: var(--color-bg-menu-active);
  }

  a {
    display: block;
    padding: 1em $menu-level-pad;
    line-height: 1.4;

    color: var(--color-bg-menu-active);

    &:hover {
      background-color: var(--color-bg-menu-hover);
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }
  }

  li > ul > li > a {
    padding-left: $menu-level-pad * 1.5;
  }

  // 3rd level
  li > ul > li > ul > li > a {
    padding-left: $menu-level-pad * 2;
  }
}

.menu-parent {
  // font-weight: bold;

  > a {
    color: var(--color-text-doc-menu);

    &:before {
      @extend %fa-icon;
      @extend .fa-solid;
      content: fa-content($fa-var-chevron-right);

      font-size: 9px;
      color: lighten($grey-dark, 30%);
      margin-left: -1em;

      position: relative;
      top: -2px;
    }
  }

  &.active, &.menu-open {
    > a:before {
      content: fa-content($fa-var-chevron-down);
    }
  }
}

.menu-toplevel {
  > li {
    border-left: $menu-highlight-bar solid $grey-dark;
  }

  > li.menu-open {
    border-left-color: $blue-lite;
  }
}
