
.post {
  p, ul {
    font-size: 18px;
    margin-bottom: 2em;
  }

  h2 {
    margin: 2em 0 1.069em 0;
  }
}

.post-header {
  padding-bottom: 1em;
  border-bottom: 1px solid var(--color-border-secondary);
  margin-bottom: 1em;

  h1 {
    line-height: 1.2;
    font-weight: 700;
    margin-top: 0;
  }
}

.post-meta {
  color: var(--color-text-tertiary);
}

.post-tags {
  margin: 1em 0;
  padding: 0;
  list-style: none;

  li {
    display: inline;

    &:after {
      content: ",";
      margin-left: -0.2em;
      margin-right: 0.2em;
    }

    &:last-child:after {
      content: "";
    }
  }
}


.post_excerpt {
  margin-bottom: 1em;

  .post-header {
    border: none;
    margin-bottom: 0;

    a {
      color: var(--color-text-primary);
      transition: color 0.5s;
    }

    a:hover, a:active {
      color: var(--color-text-link-hover);
      text-decoration: none;
    }
  }

  .post-meta time {
    font-size: 12px;
  }
}

@media only screen and (min-width: #{$bp-desk0}) {
  .post_excerpt {
    padding: 0 2em 2em 0;
    border-bottom: 1px solid var(--color-border-primary);
    margin-bottom: 3em;
  }

  .post-header {
    padding: 0 2em 1em 0;
    border-bottom: 1px solid var(--color-border-secondary);
    margin-bottom: 2em;

    h1 {
      line-height: 1.2;
      font-weight: 700;
      margin-top: 0;
      font-size: 30px;
    }
  }

  .post-content {
    padding-right: 2em;
  }
}
