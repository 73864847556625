$site-bg: #f9f9f9;

$text-black: #444;
$text-subdued: mix($site-bg, $text-black, 30%);

$grey-dark: #292e33;
$grey-darker: darken($grey-dark, 5%);
$grey-md: #a6aeb7;

$grey-lite: #e6e6e6;
$grey-lite2: #ebebeb;

$blue-dark: #063452;
$blue-lite: #00a6e6;
$blue-lite2: #78c4f6;
$blue-white: #abdaf9;

$link-blue: mix($blue-dark, $blue-lite, 50%);

$banner-grad-dark: rgba(6, 52, 81, 1);
$banner-grad-lite: rgba(12, 105, 165, 1);

$heading-line: lighten($grey-dark, 65%);

$doc-line-lite: lighten($grey-dark, 70%);

$menu-link-color: lighten($grey-dark, 55%);

@mixin banner_grad {
  // from Bootstrap
  @include gradient-horizontal($banner-grad-dark, $banner-grad-lite, 0, 100%);
}

// FIXME: Can ditch bootstrap mixin and simplify,
// linear-gradient is supported IE 10+

$grad_start_color: $banner-grad-dark;
$grad_end_color: $banner-grad-lite;

$webkit_linear_grad: -webkit-linear-gradient(left,  $grad_start_color 0, $grad_end_color 100%);
$opera_linear_grad:  -o-linear-gradient(     left,  $grad_start_color 0, $grad_end_color 100%);
$linear_grad:        linear-gradient(    to right,  $grad_start_color 0, $grad_end_color 100%);

$glphy_img: url("/assets/images/logo/banner-glyph.svg") 173% 50% no-repeat;

@mixin banner_grad_with_glyph {
  background: #{$glphy_img}, #{$webkit_linear_grad};
  background: #{$glphy_img}, #{$opera_linear_grad};
  background: #{$glphy_img}, #{$linear_grad};
}

@import "themes/light";
@import "themes/dark";
@import "themes/toggle";

@include light_theme;

@media (prefers-color-scheme: dark) {
	@include dark_theme($selector: ":root, .dark-theme");
	@include light_theme($selector: ".light-theme");
}

@media (prefers-color-scheme: light) {
	@include light_theme($selector: ":root, .light-theme");
	@include dark_theme($selector: ".dark-theme");
}
