
.site-footer {
  @include banner_grad;
  color: var(--color-text-banner);
  padding: 1em;

  .social_menu, .contact_menu {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 1em 0;

    a {
      color: var(--color-text-banner);
      padding: 0 1em;
    }
  }
}

.footer-logo {
  width: 40px;
  height: 40px;
  margin: 0 70px 0 0;
}

.footer-legal {
  font-size: 12px;
  color: var(--color-text-footer);

  a {
    color: var(--color-text-banner);
  }
}

@media only screen and (min-width: #{$bp-desk0}) {
  .site-footer {
    padding: 0;

    .site_nav {
      a {
        font-size: 14px;
        border-radius: 10px;
      }
    }

    .social_menu, .contact_menu {
      width: auto;

      li {
        float: left;
        text-align: center;
      }
    }

    .contact_menu {
      a {
        @include link_background_hover;
      }
    }

    .social_link {
      svg {
        fill: var(--color-text-footer);
      }

      svg:hover {
        fill: var(--color-text-banner);
      }

      i.fa-brands {
        font-size: 22px;
        color: var(--color-text-footer);
      }

      i.fa-brands:hover {
        color: var(--color-text-banner);
      }
    }
  } // .site-footer

  .footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
  }

  #footer_top {
    height: 95px;
  }

  #footer_bottom {
    height: 60px;
    background-color: var(--color-bg-footer);
  }

  .footer-legal {
    span {
      margin-right: 1em;
    }
  }

  .footer-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
} // @media
