.features {
  border-top: 1px solid var(--color-border-primary);
  padding: 2em 0
}

.feature {
  display: flex;
  margin: 1em 0em;
  padding: 1em;
  background-color: var(--color-bg-secondary);
  box-shadow: 0 1px 1px rgba(0,0,0,0.2);
}

.feature-img {
  width: 100px;
  height: 100px;
  margin-right: 30px;

  svg {
    width: 100%;

    #appliance, #box, #cancel, #magnifier, #outline, #symbol, #vm {
      fill: var(--color-text-primary);
    }

    #agent, #vms {
      fill: var(--color-text-tertiary);
    }

    #box_color {
      fill: var(--color-bg-tertiary);
    }
  }
}

.feature-text {
  color: var(--color-text-tertiary);
}
