body {
  background-color: var(--color-bg-primary);
  border-top: 3px solid var(--color-border-header-top);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  transition: background-color 300ms ease 0ms;
}

main {
  flex: 1;
}

.home_container {
  max-width: 867px;
  @include container-fixed(0);
}

.flex_container {
  display: flex;
  justify-content: center;
}

main img {
  @include responsive_img;
}

hr {
  border-top-color: var(--color-border-primary);
}

.community-social {
  text-indent: 1em;
  font-size: 1.5em;
}
